import { createContext, useState, useEffect } from "react"
import { useDebouncedCallback } from "source/shared/hooks/useDebounce"

export const WindowDimensionsCtx = createContext(null)

const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint: null,
  })
  const handleResize = useDebouncedCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    document.documentElement.style.setProperty(
      "--window-inner-height",
      window.innerHeight + "px",
    )
  }, 200)

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize, { passive: true })
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  )
}

export default WindowDimensionsProvider
